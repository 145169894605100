import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import './createAndEditSudent.scss'
import {IStudent} from "../../../utils/models/IStudent";
import CloseModal from "../../../assets/svg/CloseModal";
import SaveButtonIcon from "../../../assets/svg/SaveButtonIcon";
import Mark from "../../../assets/svg/Mark";
import Repeate from "../../../assets/svg/Repeate";
import GarbageMini from "../../../assets/svg/GarbageMini";
import {useCreateStudentMutation, useUpdateStudentMutation} from "../../../queries/mutations";
import {useAppSelector} from "../../../utils/helpers/customizedHooks";

interface InterfaceCreateAndEditStudentModal {
  id: number
  data?: IStudent | null
  title: string
  open: boolean
  date: string
  closeFunc: () => void
}

interface FormInterface {
  name: string
  professional_ball: number
  online_test_ball: number
  file: any
  ppt: any
  again: boolean,
  login: string,
  certificate: any
}

const CreateAndEditStudentModal: React.FC<InterfaceCreateAndEditStudentModal> = (props) => {
  const {data, id, title, open, closeFunc,date} = props

  const [selectedFile, setSelectedFile] = useState<{ fileName: string, file: File | null }>({
    file: null,
    fileName: ''
  });
  const [selectedFilePpt, setSelectedFilePpt] = useState<{ fileName: string, file: File | null }>({
    file: null,
    fileName: ''
  });
  const [selectedFileCertificate, setSelectedFileCertificate] = useState<{ fileName: string, file: File | null }>({
    file: null,
    fileName: ''
  });

  const role = useAppSelector((state) => state.user.user?.role)

  useEffect(() => {
    if (data && open) {
      console.log(data)
      form.setFieldsValue({
        name: data.name,
        file: data.file_name,
        ppt: data.ppt_name,
        online_test_ball: data?.online_test_ball,
        professional_ball: data?.professional_ball,
        again: data?.again,
        login: data?.login,
        certificate: data?.certificate_name
      })
      if(data?.certificate_name !== '0') {
        setSelectedFileCertificate({
          file: null,
          fileName: data?.certificate_name || ''
        })
      }
      if (data?.file_name !== '0') {
        setSelectedFile({
          file: null,
          fileName: data?.file_name || ''
        })
      }
      if (data?.ppt_name !== '0') {
        setSelectedFilePpt({
          file: null,
          fileName: data?.ppt_name || ''
        })
      }
    }
    // eslint-disable-next-line
  }, [open]);


  const closeAndClearFunc = () => {
    form.resetFields()
    setSelectedFile({
      file: null,
      fileName: ''
    })
    setSelectedFilePpt({
      file: null,
      fileName: ''
    })
    setSelectedFileCertificate({
      file: null,
      fileName: ''
    })
    closeFunc()
  }

  const createMutation = useCreateStudentMutation(closeAndClearFunc, id,date)
  const updateMutation = useUpdateStudentMutation(closeAndClearFunc, id,date)

  const [form] = Form.useForm<FormInterface>()

  const onFinish = (val: FormInterface) => {
    const setForm = new FormData()
    setForm.append('group_id', id as any)
    setForm.append('name', val.name as any)
    if (val.professional_ball)
      setForm.append('professional_ball', val.professional_ball as any)
    if (val.online_test_ball)
      setForm.append('online_test_ball', val.online_test_ball as any)
    if (val.login)
      setForm.append('login', val.login)
    setForm.append('certificate',selectedFileCertificate.file as File)
    setForm.append('file', selectedFile.file as File)
    setForm.append('ppt', selectedFilePpt.file as File)
    setForm.append('again', val.again as any)
    if (data?.id) {
      updateMutation.mutate({
        id: data?.id,
        form: setForm
      })
    } else {
      createMutation.mutate(setForm)
    }
  }

  const disabledFunc = () => {
    if(role === 'director')
      return false
    return data?.type === 'red' || data?.type === 'green';

  }

  return (
    <>
      {open &&
      <Modal
        visible={open}
        className={'saveModalStudent'}
        title={title}
        onCancel={closeAndClearFunc}
        closeIcon={<CloseModal/>}
        footer={false}
      >
        <Form
          form={form}
          name="createAndEditStudent"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          initialValues={{remember: true}}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className={'editInputsCont'}>
            <label style={{width: '12%', transform: 'translateY(-12px)'}}>
              F.I.O
            </label>
            <Form.Item
              name="name"
              rules={[{required: true, message: "F.I.O kiritilmagan!"}]}
            >
              <Input
                disabled={disabledFunc()}
                placeholder={'Familiya, Ism, Otasining ismi'}
                style={{width: '420px'}}/>
            </Form.Item>
          </div>
          <p>
            To‘plangan ballar
          </p>
          <div className={'ratingCount'}>
            <p>
              Kasbiy faoliyati ko’rsatgichlari
            </p>
            <div className={'inputCont'}>
              <Form.Item
                name="professional_ball"
                style={{width: '80px'}}
                rules={[
                  {
                    required: false,
                    message: " ",
                    validator: (rule, value) => {
                      const val = parseFloat(value)
                      if (data?.id) {
                        if (val > 30 || val < 0) {
                          return Promise.reject("")
                        }
                        return Promise.resolve()
                      } else {
                        if (val) {
                          if (val > 30 || val < 0) {
                            return Promise.reject("")
                          }
                          return Promise.resolve()
                        }
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input
                  disabled={disabledFunc()}
                  type={'number'}
                  style={{width: '70px'}}/>
              </Form.Item>
              <label>
                ball
              </label>
            </div>
          </div>
          <div className={'ratingCount'}>
            <p>
              Onlayn test sinovlari natijasi
            </p>
            <div className={'inputCont'}>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: " ",
                    validator: (rule, value) => {
                      const val = parseFloat(value)
                      if (data?.id) {
                        if (val > 50 || val < 0) {
                          return Promise.reject("")
                        }
                        return Promise.resolve()
                      } else {
                        if (val) {
                          if (val > 50 || val < 0) {
                            return Promise.reject("")
                          }
                          return Promise.resolve()
                        }
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
                name="online_test_ball"
                style={{width: '80px'}}
              >
                <Input
                  disabled={disabledFunc()}
                  type={'number'}
                  style={{width: '70px'}}/>
              </Form.Item>
              <label>
                ball
              </label>
            </div>
          </div>
          <div className={'ratingCount'}>
            <p>
              LOGIN(masofaviy ta'lim tizimi logini)
            </p>
            <div className={'inputCont'}>
              <Form.Item
                name="login"
                style={{width: '180px'}}
              >
                <Input
                  style={{width: '180px'}}/>
              </Form.Item>
            </div>
          </div>
          <div className={'footer'}>
            <div className={'footer_left'}>
              <Form.Item
                name="file"
                style={{width: '80%'}}
                rules={[{required: false, message: "File yuklanmagan!"}]}
              >
                {selectedFile.fileName ?
                  <div className={'repeateCont'}>
                    <span>
                      {selectedFile.fileName}
                    </span>
                    <label
                      htmlFor={'fileDownload'}
                      className={'changeFileButton'}>
                      <Repeate/>
                      <GarbageMini/>
                    </label>
                  </div>
                  :
                  <label
                    htmlFor={'fileDownload'}
                    className={'addFileButton'}>
                    <Mark/>
                    <span>
                  Bitiruv malakaviy ish
                </span>
                  </label>
                }
                <input
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length === 1) {
                      setSelectedFile({
                        file: e.target.files[0],
                        fileName: e.target.files[0].name
                      })
                      form.setFieldsValue({
                        file: e.target.files[0]
                      })
                    }
                  }}
                  id={'fileDownload'}
                  accept={'.doc,.docx,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                  type={'file'}/>
              </Form.Item>
              <Form.Item
                name="ppt"
                style={{width: '80%'}}
                rules={[{required: false, message: "File yuklanmagan!"}]}
              >
                {selectedFilePpt.fileName ?
                  <div className={'repeateCont'}>
                    <span>
                      {selectedFilePpt.fileName}
                    </span>
                    <label
                      htmlFor={'fileDownloadPpt'}
                      className={'changeFileButton'}>
                      <Repeate/>
                      <GarbageMini/>
                    </label>
                  </div>
                  :
                  <label
                    htmlFor={'fileDownloadPpt'}
                    className={'addFileButton'}>
                    <Mark/>
                    <span>
                      Taqdimot
                    </span>
                  </label>
                }
                <input
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length === 1) {
                      setSelectedFilePpt({
                        file: e.target.files[0],
                        fileName: e.target.files[0].name
                      })
                      form.setFieldsValue({
                        ppt: e.target.files[0]
                      })
                    }
                  }}
                  id={'fileDownloadPpt'}
                  accept={'.doc,.docx,.pdf,.csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation,.pps'}
                  type={'file'}/>
              </Form.Item>
              <Form.Item
                name="certificate"
                style={{width: '80%'}}
                rules={[{required: false, message: "File yuklanmagan!"}]}
              >
                {selectedFileCertificate.fileName ?
                  <div className={'repeateCont'}>
                    <span>
                      {selectedFileCertificate.fileName}
                    </span>
                    <label
                      htmlFor={'fileDownloadCertificate'}
                      className={'changeFileButton'}>
                      <Repeate/>
                      <GarbageMini/>
                    </label>
                  </div>
                  :
                  <label
                    htmlFor={'fileDownloadCertificate'}
                    className={'addFileButton'}>
                    <Mark/>
                    <span>
                      Sertifikat
                    </span>
                  </label>
                }
                <input
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length === 1) {
                      setSelectedFileCertificate({
                        file: e.target.files[0],
                        fileName: e.target.files[0].name
                      })
                      form.setFieldsValue({
                        certificate: e.target.files[0]
                      })
                    }
                  }}
                  id={'fileDownloadCertificate'}
                  accept={'.doc,.docx,.pdf,.csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation,.pps'}
                  type={'file'}/>
              </Form.Item>
            </div>
            <Button
              loading={createMutation.isLoading || updateMutation.isLoading}
              shape="round"
              type={'primary'}
              className={'saveButtonCont'}
              htmlType={'submit'}>
              <SaveButtonIcon/>
              <span>
                Saqlash
              </span>
            </Button>
          </div>
          <div>
            <Form.Item valuePropName="checked" name="again" style={{marginBottom: '0'}}>
              <Checkbox style={{marginRight: '10px'}}>
                <p>
                  Takroriy topshirayotgan tinglovchi
                </p>
              </Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      }
    </>

  )
}

export default CreateAndEditStudentModal